import CryptoJS from 'crypto-js';

/**
 * Provide functionalities to hash a string
 */
class Hashing {
  /**
   * The types of different hashing algorithms that are supported by this class
   */
  algos: Array<string>
  constructor() {
    this.algos = ['md5', 'sha1', 'sha256', 'sha224', 'sha512', 'sha384', 'sha3', 'ripemd160', /*'pbkdf2'*/];
  }

  /**
   * Hash some text
   * 
   * @param algo the hashing algorithm
   * @param value the text value to hash
   * @param salt the salt (nullable)
   * @returns the hashed string
   */
  hash(algo: string, value: string, salt?: string): string {
    switch (algo) {
      case 'md5':
        return CryptoJS.MD5(value).toString();
      case 'sha1':
        return CryptoJS.SHA1(value).toString();
      case 'sha256':
        return CryptoJS.SHA256(value).toString();
      case 'sha224':
        return CryptoJS.SHA224(value).toString();
      case 'sha512':
        return CryptoJS.SHA512(value).toString();
      case 'sha384':
        return CryptoJS.SHA384(value).toString();
      case 'sha3':
        return CryptoJS.SHA3(value).toString();
      case 'ripemd160':
        return CryptoJS.RIPEMD160(value).toString();
      default:
        throw new Error(`unsupported algo ${algo}`);
    }
  }
}

export default new Hashing();